@charset 'utf-8';

/* ========================================================================== */
/* BOOTSTRAP */
/* ========================================================================== */


@import "node_modules/bootstrap/scss/bootstrap-reboot";
@import "node_modules/bootstrap/scss/bootstrap-grid";


/* ========================================================================== */
/* VARIABLES */
/* ========================================================================== */

@import "variables/_colors.scss";
@import "variables/_fonts.scss";

/* ========================================================================== */
/* COMPONENTS */
/* ========================================================================== */

@import "components/_alert.scss";
@import "components/_article.scss";
@import "components/_autocomplete.scss";
@import "components/_blog.scss";
@import "components/_breadcrumbs.scss";
@import "components/_btn.scss";
@import "components/_carousel.scss";
@import "components/_cart-content.scss";
@import "components/_cart-heading.scss";
@import "components/_categories.scss";
@import "components/_contact.scss";
@import "components/_cookiebar.scss";
@import "components/_filter.scss";
@import "components/_footer.scss";
@import "components/_form.scss";
@import "components/_header.scss";
@import "components/_lightbox.scss";
@import "components/_modal.scss";
@import "components/_nav.scss";
@import "components/_news-section.scss";
@import "components/_news-single.scss";
@import "components/_order-history.scss";
@import "components/_page-content.scss";
@import "components/_page-heading.scss";
@import "components/_page.scss";
@import "components/_pagination.scss";
@import "components/_points.scss";
@import "components/_product-card.scss";
@import "components/_product-detail.scss";
@import "components/_products-footer.scss";
@import "components/_products-grid.scss";
@import "components/_products-heading.scss";
@import "components/_products-sort.scss";
@import "components/_range-slider.scss";
@import "components/_searchAutocomplete.scss";
@import "components/_social.scss";
@import "components/_stepper.scss";
@import "components/_subcategories.scss";
@import "components/_tag.scss";
body{
    background :$white;
    color: $gray-900;
    font-family: $font-family-primary;
    overflow-x: hidden;
    font-size: 16px;
}
body.cart #navToggle{
    display: none;
}
a{
    color: $green-500;
    text-decoration: none;

    &:hover, &:active, &:focus{
        color: $gray-900;
    }
}
img, svg {
    vertical-align: middle;
    max-width: 100%;
    height: auto;
}
strong{
    font-weight: 500;
}
h1{
    font-size: 36px;
}
.container{
    max-width: 1460px;
    padding-right: 20px;
    padding-left: 20px;
    width: 100%;
    margin: 0 auto;
}
.row {
    margin-right: -10px;
    margin-left: -10px;
}
.row>* {
    padding-right: 10px;
    padding-left: 10px;
}
@media screen and (max-width: 575px){
    .container{
        padding-right: 10px;
        padding-left: 10px;
    }
    .row {
        margin-right: -5px;
        margin-left: -5px;
    }
    .row>* {
        padding-right: 5px;
        padding-left: 5px;
    }
}

section{
    padding: 40px 0;
}
section h1{
    font-size: 32px;
    font-weight: 500;
    padding-bottom: 40px;
    margin: 0;
    color: $gray-900;
}
@media screen and (max-width: 575px){
    section{
        padding: 20px 0;
    }
    section h1{
        font-size: 24px;
        font-weight: 500;
        padding-bottom: 20px;
    }
}
section.products{
    padding-bottom: 20px;
}

section.products h1{
    padding-bottom: 20px;
}




#darkBackdrop{
    display: flex;
    opacity: 0;
    pointer-events: none;
    background: rgba(0,0,0,0.2);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}
@media screen and (max-width: 991px){
    #darkBackdrop{

    }
    body.--nav-active #darkBackdrop{
        opacity: 1;
        pointer-events: auto;
    }
    body.--search-active #darkBackdrop{
        opacity: 1;
        pointer-events: auto;
    }
    body.--filter-active #darkBackdrop{
        opacity: 1;
        pointer-events: auto;
    }
}


.logout-wrap{
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
}

.delete-account{
    margin-top: 60px;
}
.delete-account h2{
    margin-bottom: 10px;
}


.cart-aside__free-shipping{
    display: none;
}


#modal-ppl .modal__container{
    max-width: 100vw;
    max-height: 100vh;
}

#ppl-parcelshop-map .smap {
    touch-action: none;
    height: 80vh !important;
}


.product-detail__wholesale{
    margin-bottom: 40px;
}
.product-detail__wholesale-title{
    font-size: 14px;
    line-height: 1;
    color: $gray-500;
    font-weight: 500; 
    text-transform: uppercase;
    padding-bottom: 10px; 
}
.product-detail__wholesale-table{

}
.product-detail__wholesale-table td{
    padding: 8px 15px;
    background: $gray-100;
    border: 1px solid white;
}


.variants.--wholesale .variants__list-wrap,
.variants.--wholesale .variants__list{
  height: 100%;
}
.variants.--wholesale .variants__list::after{
  content: none;
}
.variants.--wholesale .variants__item.--active{
  order: initial;
  background: rgba(57, 152, 65, 0.1);
  box-shadow: inset 0px 0px 0px 1px rgb(57 152 65);
  z-index: 99;
}
.variants.--wholesale .variants__item{
  order: initial;
}
.variants.--wholesale .variants__list.--active{
  box-shadow: none;
}