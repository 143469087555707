header{
    background: $white;
}
.header-top{
    height: 40px;
    border-bottom: 1px solid $gray-300;
    display: flex;
    justify-content: center;
    align-items: center;
}
.header-top .container{
    display: flex;
}
.header-top__tel{
    display: flex;
    font-size: 14px;
    line-height: 1;
    flex-shrink: 0;
    flex-grow: 1;
    align-items: center;
}
.header-top__tel small{
    display: flex;
    font-size: 14px;
}
.header-top__tel img{
    margin: 0 10px;
    width: 16px;
    height: 16px;
}
.header-top__tel span{
    font-size: 16px;
    font-weight: 500;
    padding-right: 10px;
}

.header-top__links{
    display: flex;
    flex-shrink: 0;
    justify-content: flex-end;
}
.header-top__links a{
    font-size: 14px;
    margin: 0 0 0 20px;
    color: $gray-500;
}
@media screen and (max-width: 991px){
    .header-top{
        display: none;
    }
}


.header-main{
    padding: 20px 0;
}
.header-main .container{
    display: flex;
    align-items: center;
}
.logo{

}
.logo img{
    height: 100%;
    width: 150px;
}
#navToggle{
    height: 100%;
    width: 52px;
    cursor: pointer;
    display: none;
}
#navToggle span{
    height: 2px;
    width: 18px;
    background: #06070E;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: relative;
    transition: 0.3s all ease;

    &::before, &::after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: $gray-900;
        transform: translate(0%, -300%);
        transition: 0.3s all ease;
    }

    &::after {
        transform: translate(0%, 300%);
        width: 14px;
    }
}
#searchToggle{
    height: 100%;
    width: 52px;
    cursor: pointer;
    display: none;
    align-items: center;
    justify-content: center;

    img{
        height: 18px;
        width: 18px;
    }
}
.search{
    display: flex;
    flex-grow: 1;
    justify-content: center;
    padding: 0 60px;
}
.search-wrapper{
    max-width: 480px;
    min-width: auto;
    width: 100%;
}
.search-form{
    display: flex;
    position: relative;
}
.search__input{
    background: $gray-100;
    border: 0;
    width: 100%;
    outline: none;
    height: 52px;
}
.search__button{
    border: 0;
    background: $gray-100;
    height: 52px;
    width: 52px;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.header-nav{
    display: flex;
}
.header-nav .user{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 30px 0 0;
    cursor: pointer;
    position: relative;
}
.header-nav .user.--active .user__title{
    font-weight: 500;
}
.header-nav .user__wrap{
    display: flex;
    flex-direction: column;
}
.header-nav .user__title{
    color: $gray-900;
}
.header-nav .user__text{
    color: $gray-900;
    font-size: 14px;
    line-height: 1;
}
.header-nav .user__icon{
    background: $gray-100;
    border-radius: 50%;
    height: 48px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
}
.header-nav .user__icon img{
    height: 20px;
    width: 16px;
}
.header-nav .user__dropdown{
    position: absolute;
    margin-top: 10px;
    top: 100%;
    left: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    width: 220px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    border: 1px solid $gray-300;
    background: $white;
    padding-bottom: 10px;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s ease opacity;

    &::before{
        content: "";
        height: 12px;
        width: 12px;
        display: inline-flex;
        background: $white;
        border-top: 1px solid $gray-300;
        border-right: 1px solid $gray-300;
        position: relative;
        transform: translateY(-6px) rotate(-45deg);
        margin-left: 18px;
    }
    a{
        background: $white;
        padding: 10px 20px;
        color: $gray-900;
        &:hover, &:active, &:focus{
            background: $gray-100;
        }
    }
}
body.--user-active .header-nav .user__dropdown{
    opacity: 1;
    pointer-events: auto;
}
.header-nav .user img{

}
.header-nav .cart{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0px 0 30px;
    color: $gray-900;
}
.header-nav .cart__icon{
    background: $gray-100;
    border-radius: 50%;
    height: 48px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    position: relative;
}  
.header-nav .cart__icon img{
    width: 18px;
    height: 20px;
}
.header-nav .cart__number{
    position: absolute;
    background: $green-500;
    color: $white;
    border: 2px solid $gray-100;
    font-size: 11px;
    font-weight: 500;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    line-height: 1;
    text-align: center;
    top: 24px;
    left: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.header-nav .cart img{
    
}
.header-nav .cart-wrap{
    
}
.header-nav .cart-title{
    font-size: 16px;
}
.header-nav .cart.--active .cart-title{
    font-size: 14px;
    line-height: 1;
}
.header-nav .cart-price{
    font-size: 16px;
    font-weight: 500;
}

@media screen and (max-width: 991px){
    header {
        padding-bottom: 52px;
    }
    .header-main{
        padding: 0;
        height: 52px;
        background: $white;
        position: fixed;
        z-index: 5;
        top: 0;
        left: 0;
        width: 100%;
        box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.05);
    }
    .header-main .container{
        padding: 0;
        height: 100%;
    }
    .logo img{
        margin-left: 10px;
    }
    .logo img{
        height: 34px;
        width: auto;
    }
    #navToggle, #searchToggle{
        display: flex;
        flex-shrink: 0;
    }
    .search{
        padding: 0;
    }
    .search-wrapper{
        display: none;
        
    }
    body.--search-active{
        overflow-y: hidden;
    }
    body.--search-active .header-main{
        z-index: 15;
    }
    body.--search-active  .search-wrapper{
        display: flex;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        max-width: 100%;
        box-shadow: 0px 10px 8px -8px rgba(0,0,0,0.05);
        border: 10px solid $white;
        background: $white;
        border-top: 0;

        .search-form{
            width: 100%;
        }
    }
    .header-nav {
        height: 100%;
    }
    .header-nav .user{
        padding: 0;
        margin: 0;
    }
    .header-nav .user__title{
        display: none;
    }
    .header-nav .user__text{
        display: none;
    }
    .header-nav .user__icon{
        margin: 0;
        border-radius: 0;
        background: transparent;
        height: 100%;
        width: 52px;
    }
    .header-nav .cart{
        padding: 0;
        margin: 0;
    }
    .header-nav .cart__icon{
        margin: 0;
        border-radius: 0;
        background: transparent;
        height: 100%;
        width: 52px;
    }
    .header-nav .cart-wrap{
        display: none;
    }
    .header-nav .cart__number{ 
        border: 2px solid $white;
        top: 25px;
        left: 24px;
    }
    .header-nav .user__dropdown {
        right: -42px;
        left: auto;

        &::before{
            margin-left: 145px;
        }
    }
}
